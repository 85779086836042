import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c(VBreadcrumbs,{attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"page-title"},[_vm._v("Edit User "+_vm._s(_vm.userData.displayName && `- ${_vm.userData.displayName}`))]),_c(VSpacer),_c(VBtn,{attrs:{"loading":_vm.isLoading,"icon":"","small":""},on:{"click":function($event){return _vm._getUser(true)}}},[_c(VIcon,[_vm._v("mdi-refresh")])],1)],1),(_vm.userData.customClaims && _vm.userData.customClaims.admin)?_c('div',{staticClass:"d-flex align-center body-2 font-weight-bold primary--text mb-2"},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":"","color":"primary"}},[_vm._v("mdi-security")]),_vm._v("Administrator ")],1):_vm._e(),_c('p',{staticClass:"body-2"},[_c('span',{staticClass:"font-weight-bold mr-1"},[_vm._v("ID")]),_c('copy-label',{attrs:{"text":_vm.user.uid}}),_c('br'),_c('span',{staticClass:"font-weight-bold mr-1"},[_vm._v("Email")]),_c('copy-label',{attrs:{"text":_vm.userData.email}})],1),_c(VTabs,{attrs:{"show-arrows":false,"background-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,{attrs:{"to":"#tabs-account"}},[_vm._v("Account")])],1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{attrs:{"value":"tabs-account"}},[_c('account-tab',{ref:"tabs-account"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }